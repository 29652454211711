body {
    font-family: 'Montserrat', sans-serif !important;
}

.btn {
    border-radius: 50em !important;
    font-weight: 500;
}

.btn-lg {
    font-size: 14px;
}

.btn-sm {
    font-size: 13px;
}

.bg-red {
    background-color: #7e33ff;
}

.bg-green {
    background-color: #008b00;
}

.bg-light-blue {
    background-color: #45e099;
}

.bg-dark-blue {
    background-color: #002626;
}

.color-white {
    color: #fff !important;
}

.color-red {
    color: #7e33ff !important;
}

.color-dark-blue {
    color: #001515 !important;
}

.color-light-blue {
    color: #45e099;
}

.color-black {
    color: #000;
}

.container-header {
    background-image:
        linear-gradient(to right,
            #001515, #002626);
}

.container-header,
.container-carousel,
.carousel,
.carousel-item {
    height: 600px;
}

.container-header-pags-internas {
    height: auto;
    margin-bottom: 30px;
}

.navbar {
    background-color: transparent !important;
    padding: 4% 5%;
}

.btn-white {
    border: 2px solid #fff;
}

.link-logo {
    margin-right: 5%;
}

.img-logo {
    object-fit: cover;
    height: 45px;
}

.container-menu {
    margin-right: 10%;
}

.container-btn-header {
    margin-right: 2%;
}

.container-btn-header .nav-item:not(:last-child) {
    margin-right: 15px;
}


.icone-sociais {
    object-fit: cover;
    width: 32px;
}

.titulo {
    font-size: 38px;
    font-weight: 700;
}

.carousel-indicators {
    display: inline-block;
    top: 20%;
    left: auto;
    bottom: auto;
    margin: 0;
}

.carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: transparent;
}

.carousel-indicators li:not(:last-child) {
    margin-bottom: 40px;
}

.carousel-indicators li:not(:last-child)::before {
    display: block;
    content: '';
    width: 2px;
    height: 43px;
    background-color: #fff;
    position: absolute;
    left: 48%;
    top: 22px;
}

.carousel-indicators .active {
    background-color: #45e099;
}

.carousel {
    width: 100% !important;
}



.institucional-carousel {
    width: 37%;
    margin-top: 5%;
}

.container-carousel-familia,
.container-carousel-empresa {
    background-repeat: no-repeat;
    background-position: 100% 12%;
}

.container-carousel-familia {
    background-image: url('/public/imagens/para-sua-familia.png');
}

.container-carousel-empresa {
    background-image: url('/public/imagens/para-sua-empresa.png');
}

.lead {
    font-size: 28px;
    font-weight: 500;
}

.container-passos {
    border-radius: 20px;
    padding: 70px 33px;
    position: relative;
    margin-top: -16%;
}

.container-passo {
    width: 145px;
    text-align: center;
    position: relative;
    float: left;
}

.container-passo:not(:last-child) {
    margin-right: 4%;
}

.texto-passos {
    width: 310px;
    border-color: #fff;
    border-width: 0 2px 0 0;
    border-style: dashed;
    padding-right: 33px;
    margin-right: 33px;
    float: left;
}

.texto-passos h2 {
    font-size: 22px;
    font-weight: 700;
}

/*.passos
{
    display: flex;
    flex: 4;
    justify-content: space-between;
}*/

.icone-passos {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 85px;
    height: 85px;
}

.border-passos {
    border: 1px dashed #fff;
    border-radius: 50%;
    padding: 10px;
    display: inline-block;
}

.container-icone-passos::before {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #7e33ff;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    left: 8px;
}

#primeiro-passo::before {
    content: '01';
}

#segundo-passo::before {
    content: '02';
}

#terceiro-passo::before {
    content: '03';
}


#quarto-passo::before {
    content: '04';
}

.icone-passos img {
    width: 50px;
}

.descricao-passo {
    font-size: 13px;
    font-weight: 700;
}

.titulo-pagina {
    background-color: #2e2c7a;
    padding: 0 0 50px 0;
}

.titulo-pagina h1 {
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 40px;
}

.breadcrumbs {
    padding-top: 10px;
}

.breadcrumbs span {
    text-transform: capitalize;
    font-size: 11px;
}

.breadcrumbs span.anterior {
    background: url('/public/imagens/icone-seta-breadcrumbs.png') no-repeat right center;
    padding: 0 10px 0 0;
    margin-right: 3px;
}

.breadcrumbs span.anterior:hover {
    text-decoration: underline;
    cursor: pointer;
}

.texto-institucional-home {
    padding: 155px 0 0 0;
}

.texto-institucional-home h2 {
    font-size: 13px;
}

.texto-institucional-home h3 {
    font-size: 38px;
    font-weight: bold;
}

.texto-institucional-home p {
    font-size: 12px;
    display: inline-block;
    max-width: 400px;
}

.img-institucional-home {
    width: 600px;
}

section.planos-home {
    position: relative;
    top: -120px;
    margin-bottom: -120px;
}

.container-beneficios {
    background-color: #002626;
    position: relative;
    padding: 0px 0 10px 0;
}

.container-beneficios h2 {
    font-size: 16px;
}

.container-beneficios h3 {
    color: white;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 70px;
}

#svg-topo-planos {
    margin-bottom: -1px;
}

.box-plano {
    width: 285px;
    position: relative;
    z-index: 0;
    display: block;
    text-align: center;
    margin: 0 auto 60px auto;
}

.topo-plano {
    position: relative;
}

.circulo-topo-plano {
    background-color: white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 0 10px #333;
}

.circulo-topo-plano .texto {
    margin-top: 12px;
}

.circulo-topo-plano .texto-maior {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
}

.circulo-topo-plano .texto-menor {
    font-size: 11px;
    margin-top: -8px
}

.shadow-svg-topo-plano {
    -webkit-filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, .7));
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
}

.cls-1 {
    fill: none;
}

.cls-2 {
    clip-path: url(#clip-path);
}

#bg-svg-topo-plano-1 {
    --color-stop-1: #e14452;
    --color-stop-2: #de336b;
    --color-stop-3: #dc267f;
}

#bg-svg-topo-plano-2 {
    --color-stop-1: #FF5601;
    --color-stop-2: #FC9F05;
    --color-stop-3: #E6B53C;
}

#bg-svg-topo-plano-3 {
    --color-stop-1: #48B344;
    --color-stop-2: #07C0C5;
    --color-stop-3: #013EB9;
}

#bg-svg-topo-plano-4 {
    --color-stop-1: #020087;
    --color-stop-2: #90009F;
    --color-stop-3: #EA1C80;
}

.bg-svg-topo-plano-1 {
    fill: url(#bg-svg-topo-plano-1) #de336b;
    box-shadow: 10px 10px 10px #000;
}

.bg-svg-topo-plano-2 {
    fill: url(#bg-svg-topo-plano-2) #FC9F05;
    box-shadow: 10px 10px 10px #000;
}

.bg-svg-topo-plano-3 {
    fill: url(#bg-svg-topo-plano-3) #07C0C5;
    box-shadow: 10px 10px 10px #000;
}

.bg-svg-topo-plano-4 {
    fill: url(#bg-svg-topo-plano-4) #90009F;
    box-shadow: 10px 10px 10px #000;
}

.bg-btn-plano-1 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e14452+0,de336b+50,dc267f+100 */
    background: #e14452;
    /* Old browsers */
    background: -moz-linear-gradient(left, #e14452 0%, #de336b 50%, #dc267f 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #e14452 0%, #de336b 50%, #dc267f 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #e14452 0%, #de336b 50%, #dc267f 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e14452', endColorstr='#dc267f', GradientType=1);
    /* IE6-9 */
}

.bg-btn-plano-2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff5601+0,fc9f05+50,e6b53c+100 */
    background: #ff5601;
    /* Old browsers */
    background: -moz-linear-gradient(left, #ff5601 0%, #fc9f05 50%, #e6b53c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ff5601 0%, #fc9f05 50%, #e6b53c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ff5601 0%, #fc9f05 50%, #e6b53c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5601', endColorstr='#e6b53c', GradientType=1);
    /* IE6-9 */
}

.bg-btn-plano-3 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#48b344+0,07c0c5+50,013eb9+100 */
    background: #48b344;
    /* Old browsers */
    background: -moz-linear-gradient(left, #48b344 0%, #07c0c5 50%, #013eb9 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #48b344 0%, #07c0c5 50%, #013eb9 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #48b344 0%, #07c0c5 50%, #013eb9 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#48b344', endColorstr='#013eb9', GradientType=1);
    /* IE6-9 */
}

.bg-btn-plano-4 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#020087+0,90009f+50,ea1c80+100 */
    background: #020087;
    /* Old browsers */
    background: -moz-linear-gradient(left, #020087 0%, #90009f 50%, #ea1c80 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #020087 0%, #90009f 50%, #ea1c80 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #020087 0%, #90009f 50%, #ea1c80 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#020087', endColorstr='#ea1c80', GradientType=1);
    /* IE6-9 */
}


.bg-grad-azul {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a1b35+0,261a62+100 */
    background: #0a1b35;
    /* Old browsers */
    background: -moz-linear-gradient(left, #0a1b35 0%, #261a62 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #0a1b35 0%, #261a62 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #0a1b35 0%, #261a62 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a1b35', endColorstr='#261a62', GradientType=1);
    /* IE6-9 */
}

.btn-planos-home {
    width: auto;
    height: 38px;
    line-height: 38px;
    border-radius: 50em !important;
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: 20px auto 0 auto;
}

.titulo-topo-plano {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 60px;
}

ul.lista-plano {
    display: block;
    position: relative;
    width: 250px;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: -20px auto 0 auto;
    z-index: -1
}

ul.lista-plano li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 13px;
    height: 30px;
    font-size: 13px;
    color: #000;
    text-align: center;
}

ul.lista-plano li:nth-child(even) {
    background: #d2ffef
}

ul.lista-plano li:nth-child(odd) {
    background: #B1DEE4
}

ul.lista-plano li:first-child {
    background: #fff
}

ul.lista-plano {
    border: 1px solid #B9C8D3
}

section.beneficios-home {
    margin-bottom: 50px;
}

.slide-beneficios-home {
    text-align: center;
}

.slide-beneficios-home h2 {
    font-size: 16px;
    margin: 70px 0 0 0;
}

.slide-beneficios-home h3 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 50px;
}

ul.slide-menu-items {
    padding-right: 10px;
    height: 330px;
    overflow: auto;
}

ul.slide-menu-items li {
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    background-color: #d2ffef;
    border-bottom: 1px dashed #45e099;
}

ul.slide-menu-items li:hover {
    border-bottom: 1px dashed #001515;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a1b35+0,261a62+100 */
    background: #0a1b35;
    /* Old browsers */
    background: -moz-linear-gradient(left, #001515 0%, #002626 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #001515 0%, #002626 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #001515 0%, #002626 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a1b35', endColorstr='#261a62', GradientType=1);
    /* IE6-9 */
}

.img-icone-slide-home {
    width: 60px;
    height: 60px;
}

.texto-item-slide-home {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    padding-top: 5px;
    margin-left: 10px;
}

.logo-rede-mais-saude {
    width: 100px;
    margin-bottom: 1rem;
}

ul.slide-menu-items li:hover .texto-item-slide-home {
    color: white !important;
}

.container-pagina-interna {
    padding: 20px 0;
}

.exibe-slide-container {
    background-color: #d2ffef;
    border-radius: 20px;
    min-height: 330px;
}

.conteudo-slide-home {
    /*display: flex;*/
    box-sizing: content-box;
    padding: 25px;
}

.conteudo-slide-home .row {
    display: none;
}

/*.conteudo-slide-home .img-slide-box img{width: 280px; height: 280px; }*/
.conteudo-slide-home .texto-slide-box {
    margin: 0 0 0 10px;
}

.conteudo-slide-home .img-slide-box {
    border-radius: 10px;
    overflow: hidden;
}

.conteudo-slide-home .texto-slide-box .passo {
    font-weight: bold;
    font-size: 13px;
    display: block;
}

.conteudo-slide-home .texto-slide-box h4 {
    font-size: 20px;
    font-weight: bold;
}

.conteudo-slide-home .texto-slide-box p {
    font-size: 14px;
    line-height: 20px;
}

.conteudo-slide-home .texto-slide-box p.frase-final {
    font-size: 16px;
    color: #05CCCB;
    font-weight: bold;
}

.newsletter-home {
    background-color: #06C7CC;
    padding: 70px 0;
    margin: 80px 0 0 0;
    border-bottom: 1px solid #C0EDF2;
}

/*.newsletter-home .container, .newsletter-home .container > div {display: flex;} */
.newsletter-home img {
    width: 80px;
    height: 80px;
}

.newsletter-home .conteudo-newsletter {
    display: flex;
}

.newsletter-home .texto-newsletter {
    margin: 0 0 0 15px;
}

.newsletter-home .texto-newsletter h2 {
    font-size: 13px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}

.newsletter-home .texto-newsletter p {
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
}

.newsletter-home .form-newsletter {
    margin-top: 35px;
}

.newsletter-home .form-newsletter input {
    max-width: 310px;
    border: none;
    border-radius: 50em;
    height: 38px;
    line-height: 38px;
    font-size: 11px;
    font-weight: bold;
    text-indent: 10px;
}

.newsletter-home .form-newsletter .btn-newsletter {
    height: 38px;
    margin-left: -20px;
    padding: 0 25px;
    font-size: 12px;
    font-weight: normal;
    background-color: #001515;
    color: white;
    z-index: 4;
    border: none
}


section.footer .container {
    padding: 40px 0;
}

section.footer .container .logo-footer {
    width: 150px;
}

section.footer .container p {
    font-size: 11px;
    margin: 10px 0 0 0;
}

section.footer .container .contato-footer {
    font-size: 11px;
    margin-top: 5px;
}

section.footer .container .contato-footer img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}


section.footer .container h3 {
    font-size: 25px;
}

section.footer .container ul.menu-footer {
    list-style: square inside url("/public/imagens/icone-seta-footer.jpg");
    width: 360px;
}

section.footer .container ul.menu-footer li {
    float: left;
    width: 50%;
    margin-bottom: 5px;
}

section.footer .container ul.menu-footer li a {
    font-size: 12px;
    font-weight: 100;
}

section.footer .container .btn-entre-contato {
    display: inline-block;
    font-weight: bold;
    clear: both;
    margin-top: 20px;
}

section.footer .container .redes-sociais ul.navbar-nav {
    display: inline-block;
}

section.footer .container .redes-sociais ul.navbar-nav li {
    float: left;
    margin-right: 10px;
}

section.footer .container .redes-sociais ul.navbar-nav li a {
    display: inline;
    float: left;
}

section.copyright {
    background-color: #45e099;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
}

form label {
    font-weight: bold;
    font-size: 14px;
}

.container-form {
    padding: 50px 0;
}

.btn-quadrado {
    border-radius: 0px !important;
}

.error-message {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.container-btn-beneficios {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#ligaTag {
    background: url('/public/imagens/icone_tag_phone.png') center left 7px no-repeat #eee;
    top: 150px;
}

.abaClicavel {
    transition: all 0.5s;
    position: fixed;
    z-index: 99;
    cursor: pointer;
    right: 0px;
    height: 50px;
    padding: 10px 0px 10px 50px;
    width: 0px;
    border-radius: 10px 0px 0px 10px;
    font: 16px/16px calibri;
    color: #000;
}

.abaClicavel div {
    padding-top: 5px
}

.abaClicavel:hover {
    width: 200px;
    padding-right: 10px;
}


/* scrollbar */
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EEF3F7;
    background-color: #EEF3F7;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #7e33ff;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7e33ff;
}

@media (max-width: 992px) {

    .navbar-light .navbar-toggler-icon {
        background-image: url('/public/imagens//btn-menu-mobile.png');
    }

    .container-sociais {
        display: none;
    }

    .container-btn-header .navbar-nav .nav-item {
        border-bottom: 1px solid #ffffff6b;
        margin: 0;
    }

    .container-btn-header .navbar-nav .nav-item .btn {
        border: none;
        font-size: 16px !important;
        font-weight: normal;
        padding: .5rem 1rem;
    }

    .container-btn-header .navbar-nav .nav-btn-red {
        border-bottom: none;
    }

    .container-btn-header .btn:hover {
        border: none !important;
        background-color: unset !important;
    }

    .container-menu,
    .container-btn-header {
        text-align: center;
        margin: 0;
    }

    .container-menu .navbar-nav .nav-item:not(:last-child) {
        border-bottom: 1px solid #ffffff6b;
    }

    .container-carousel-familia,
    .container-carousel-empresa {
        background-size: 120% !important;
    }

    .texto-passos {
        display: block;
        width: 100%;
        text-align: center;
        border-width: 0 0 2px 0;
        margin-bottom: 10%;
    }

    .passos {
        display: inline-block;
        width: 100%;
    }

    .container-passo {
        margin-right: 3%;
        float: none;
        display: inherit;
    }

    .container-carousel {
        margin: 0;
    }

    .institucional-carousel {
        width: 100%;
        text-align: center;
    }

    .container-carousel-familia,
    .container-carousel-empresa {
        background-position: center;
        background-size: cover;
    }

    .container-btn-header .bg-red {
        width: 100%;
        border-radius: unset !important;
    }
}


@media (max-width: 767px) {
    * {
        text-align: center !important;
    }

    .newsletter-home {
        margin-top: 0;
    }

    .menu-footer,
    .redes-sociais {
        margin-top: 30px;
    }

    section.footer .container ul.menu-footer {
        width: auto;
    }

    .newsletter-home .conteudo-newsletter {
        display: inline-block;
    }

    .img-slide-box {
        width: 58%;
        margin: 0 auto;
    }

    .navbar-light .navbar-toggler {
        color: unset;
    }

    .container-passos {
        border-radius: 0;
    }

    .carousel-indicators {
        display: flex;
        top: 15px;
        right: 38%;
        left: unset;
    }

    .carousel-indicators li:not(:last-child) {
        margin: 0 37px 0 0;
    }

    .carousel-indicators li:not(:last-child)::before {
        width: 43px;
        height: 2px;
        left: 25%;
        top: 10px;
    }

    .carousel-inner {
        display: inline-block;
        margin-top: 60px;
    }

    .texto-institucional-home {
        padding-top: 50px;
    }



}

@media (max-width: 576px) {

    .container-carousel {
        padding: 0;
    }

    .container-carousel-familia,
    .container-carousel-empresa {
        padding: 0 30px;
    }

    .titulo {
        font-size: 28px;
    }

    .lead {
        font-size: 23px;
    }

    .carousel-item .btn {
        font-size: 13px;
        padding: .25rem .5rem;
        line-height: 1.5;
    }

    .titulo-pagina h1 {
        font-size: 26px;
        margin-top: 30px;
    }

    .titulo-pagina {
        padding: 0 0 30px 0;
    }
}

@media (max-width: 386px) {
    .container-passos {
        padding: 70px 20px;
    }

    .container-passo {
        margin-right: 2% !important;
    }

    .descricao-passo,
    .etapa {
        font-size: 13px !important;
    }
}